<template>
  <div class="wrapper">
    <div ref="eMenu" class="toolbar"></div>
    <div ref="editor" style="text-align:left" class="text"></div>
    <YTIcon :href="'#icon-shanchu'" class="removeEditor" v-if="iconShow" @click="handleRemove" />
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import E from 'wangeditor'
import ossApi from '@api/oss.js'
export default {
  name: 'RichEditor',
  components: {
    YTIcon
  },
  props: {
    value: {
      type: String
    },
    iconShow: Boolean,
    defaultText: {
      default: '',
      value: String
    }
  },
  data() {
    return {
      editor: null
    }
  },
  mounted() {
    this.editor = new E(this.$refs.eMenu, this.$refs.editor)
    this.$nextTick(() => {
      this.editor.txt.html(this.value)
    })
    this.editor.config.menus = ['bold', 'italic', 'underline', 'image', 'code']
    this.editor.config.uploadImgServer = '/'
    this.editor.config.pasteText = true
    this.editor.config.onchange = this.handleInput
    this.editor.config.onfocus = value => {
      if (value === '') {
        return
      }
      this.changeColor('#448bff')
    }
    this.editor.config.onblur = value => {
      if (value === '') {
        return
      }
      this.$emit('validate')
      this.changeColor('#CCCCCC')
    }
    this.editor.config.placeholder = this.defaultText
    this.editor.config.customUploadImg = (files, insert) => {
      let newFile = this.$renameFile(files[0])
      if (!window.uploadUrl) {
        ossApi.getQuestionFileToken(newFile.name).then(res => {
          const data = res.res
          const formData = new FormData()
          formData.append('key', data.dir)
          formData.append('OSSAccessKeyId', data.accessId)
          formData.append('policy', data.policy)
          formData.append('Signature', data.signature)
          formData.append('file', newFile)
          const url = `https://${data.host}`
          fetch(url, {
            method: 'POST',
            body: formData
          }).then(() => {
            const fullPath = `https://${data.host}/${data.dir}`
            insert(fullPath)
          })
        })
      } else {
        let formData = new FormData()
        formData.append('file', newFile)
        ossApi.upCover(formData).then(res => {
          insert(res.res)
        })
      }
    }
    this.editor.create()
  },
  methods: {
    handleRemove() {
      this.$emit('on-success')
    },
    changeColor(color) {
      if (this.$refs.eMenu && this.$refs.editor) {
        this.$refs.eMenu.style.borderColor = color
        this.$refs.editor.style.borderColor = color
      }
    },
    handleInput(newVal) {
      this.$emit('input', newVal)
    },
    clearEditor() {
      this.editor.txt.clear()
    },
    modifyEditor(data) {
      this.editor.txt.html(data)
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  .w-e-text-container {
    /*height: 100px !important;*/
    height: auto;
    padding: 1px 0;
  }
}

.toolbar {
  border: 1px solid #ccc;
  border-bottom: none;
  height: 40px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
::v-deep .w-e-toolbar {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
div ::v-deep i {
  font-size: 16px;
}
/*div ::v-deep .w-e-text-container {*/
/*  z-index: 0 !important;*/
/*}*/
.text {
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
::v-deep .w-e-text-container {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.active {
  cursor: s-resize;
}
.bottom-icon {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 16px;
  bottom: 0;
  text-align: center;
  line-height: 20px;
  z-index: 10001;
}
.removeEditor {
  width: 16px;
  height: 15px;
  position: absolute;
  right: 180px;
  top: 10px;
  z-index: 10002;
  &:hover {
    color: #448bff;
  }
}
::v-deep .w-e-toolbar {
  z-index: unset !important;
}
::v-deep xmp {
  width: 100%;
  white-space: pre-wrap;
}
</style>
