import { render, staticRenderFns } from "./RichEditor.vue?vue&type=template&id=78e2b4ef&scoped=true&"
import script from "./RichEditor.vue?vue&type=script&lang=js&"
export * from "./RichEditor.vue?vue&type=script&lang=js&"
import style0 from "./RichEditor.vue?vue&type=style&index=0&id=78e2b4ef&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78e2b4ef",
  null
  
)

export default component.exports